@import '../../assets/scss/color.scss';

.lint-custom-modal {
  .btn-close{
    background: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
  .modal-title {
    color: $color_ultramarine !important;
    font-size: 18px !important;
    font-weight: 600;
    line-height: normal;
  }

  .modal-90w {
    max-width: none !important;
    width: 90% !important;
  }

  .modal-header {
    padding: 0;
  }
  .modal-content {
    border-color: $color_ultramarine !important;
    padding: 20px;
  }

  .close-icon {
    cursor: pointer;
    position: relative;
    bottom: 5px;
    color: #000000;
    opacity: 0.5;

    &:hover {
      opacity: 0.75;
    }
  }

  .modal-body {
    font-size: 16px;
    font-style: normal;
    padding: 20px 0;
    font-weight: 400;
    line-height: 25px;
  }

  .editor-custom-cancel-button,
  .editor-custom-button {
    padding: 5px 20px;
    font-size: 14px;
    color: $color_ultramarine !important;
    border-color: $color_ultramarine !important;
  }

  .editor-custom-cancel-button {
    &:hover,
    &:active,
    &:focus-visible {
      background-color: transparent !important;
      color: $color_ultramarine !important;
      border-color: $color_ultramarine !important;
    }
  }

  .modal-footer {
    border: 0 !important;
    padding: 0px;
  }

  .editor-btn-primary {
    background-color: $color_ultramarine !important;
    color: #fff !important;
  }
}

.custom-base-modal {
  .modal-body {
    padding: 1rem !important;
  }

  .modal-header {
    padding: 1rem 1rem 0.5rem 0 !important;
    border-bottom: 1px solid #dee2e6 !important;
  }

  .modal-footer {
    padding: 0.75rem !important;
  }
}

.exit-preview-btn.btn-primary {
  padding: 7px 20px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--loree-blue-primary-color, #129);
  background: #112299;
  color: #fff;
  text-align: center;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 600;
  opacity: 1;

  &:active,
  &:focus-visible,
  &:hover {
    background-color: #112299 !important;
    color: #fff !important;
  }
}

.preview-modal-body {
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}
