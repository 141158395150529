.interactive-prgs {
    background-color: #ffffff;

    .prgs-blue {
        background-color: #0B099C;
        width: 80%;
        height: 10px;
    }

    .prgs-red {
        background-color: #D0302B;
        width: 80%;
        height: 10px;
    }

    .prgs-orange {
        background-color: #F7BA44;
        width: 80%;
        height: 10px;
    }

    .prgs-green {
        background-color: #72C069;
        width: 80%;
        height: 10px;
    }
}

.loreeic {
    position: absolute;
    right: 1%;
    top: 13%;
    visibility: hidden;
}

.iCard:hover {
    .loreeic {
        position: absolute;
        right: 1%;
        top: 13%;
        visibility: visible;
        font-weight: bold;
        opacity: 1;
        transition: opacity 2s ease-in;
    }
}

.card-create-btn {
    padding: 7px 20px !important;
    background: #112299 !important;
    border-radius: 4px !important;
    border-color: #112299 !important;

    &:hover {
        background-color: #112299;
    }
}

.card-style {
    border-radius: 6px;
    border: 1px solid #D8D8D8;
    background: #FFF;
}

.text-color {
    color: #112299 !important;
    font-size: 16px !important;
}

.title-body{
    margin-left: 16px;
}

.description-text {
    font-size: 14px;
    padding-bottom: 10px;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1260px !important;
    }
}

@media (min-width: 768px) {
    .landingPageLoreeInteractive .col-md-3 {
        flex: 0 0 auto;
        width: 20% !important;
    }
}
