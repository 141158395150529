@import '../../assets/scss/color.scss';

.lint-custom-tab-section {
  .nav-tabs {
    border-bottom: 0px !important;
    .nav-item {
      border-bottom: 1px solid #dee2e6;
      flex: none !important;
    }
    .nav-link {
      font-size: 14px;
      padding: 5px 15px !important;
      background-color: white !important;
      margin-bottom: 0 !important;
      color: #707070 !important;
      border: none !important;

      &:hover {
        color: $color_ultramarine !important;
        border-bottom: 2px solid $color_ultramarine !important;
      }
    }

    .nav-link.active {
      color: $color_ultramarine !important;
      border-bottom: 2px solid $color_ultramarine !important;
    }
  }
  .tab-pane {
    height: 230px !important;
  }
}
