.navbar-tabs {
    .nav-tabs {
        .nav-link {
            font-size: 14px;
            padding: 10px 15px !important;
            background-color: white !important;
            margin-bottom: 0 !important;
            color: #707070 !important;
            border: none !important;

            &:hover {
                color: #112299 !important;
                border-bottom: 2px solid #112299 !important;
            }
        }

        .nav-link.active {
            color: #112299 !important;
            border-bottom: 2px solid #112299 !important;
        }

    }
}