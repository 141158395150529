.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

blockquote {
  border-left: 4px solid rgb(211, 207, 207);
  padding: 15px !important;
  margin-top: 4px !important;
}

.display {
  display: block;
}

pre {
  display: block;
  font-size: 87.5%;
  background-color: rgb(211, 207, 207);
}

li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}

li.ql-indent-1 {
  counter-increment: list-1;
}

li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}

li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

li.ql-indent-2 {
  counter-increment: list-2;
}

li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}

li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

li.ql-indent-3 {
  counter-increment: list-3;
}

li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}

li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

li.ql-indent-4 {
  counter-increment: list-4;
}

li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}

li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

li.ql-indent-5 {
  counter-increment: list-5;
}

li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}

li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

li.ql-indent-6 {
  counter-increment: list-6;
}

li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}

li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

li.ql-indent-7 {
  counter-increment: list-7;
}

li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}

.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.ql-indent-8 {
  counter-increment: list-8;
}

.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}

.ql-indent-8 {
  counter-reset: list-9;
}

.ql-indent-9 {
  counter-increment: list-9;
}

.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}

.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

// font-family
.ql-font span[data-value='none']::before,
.ql-font span[data-label='None']::before {
  font-family: 'none';
}
.ql-font span[data-value='arial']::before,
.ql-font span[data-label='Arial']::before {
  font-family: 'Arial';
}

.ql-font span[data-value='arialBlack']::before,
.ql-font span[data-label='Arial Black']::before {
  font-family: 'Arial Black';
}

.ql-font span[data-value='brushScriptMT']::before,
.ql-font span[data-label='Brush Script MT']::before {
  font-family: 'Brush Script MT';
}

.ql-font span[data-value='comicNewCourier']::before,
.ql-font span[data-label='Comic New Courier']::before {
  font-family: 'Comic New Courier, monospace';
}

.ql-font span[data-value='georgia']::before,
.ql-font span[data-label='Georgia']::before {
  font-family: 'Georgia';
}

.ql-font span[data-value='helvetica']::before,
.ql-font span[data-label='Helvetica']::before {
  font-family: 'Helvetica';
}
.ql-font span[data-value='latoBold']::before,
.ql-font span[data-label='latoBold']::before {
  font-family: 'Lato Bold';
}
.ql-font span[data-value='latoItalic']::before,
.ql-font span[data-label='latoItalic']::before {
  font-family: 'Lato Italic';
}
.ql-font span[data-value='latoRegular']::before,
.ql-font span[data-label='latoRegular']::before {
  font-family: 'Lato Regular';
}

.ql-font span[data-value='lucida']::before,
.ql-font span[data-label='Lucida']::before {
  font-family: 'Lucida';
}

.ql-font span[data-value='lucidaSansUnicode']::before,
.ql-font span[data-label='Sans Unicode']::before {
  font-family: 'Lucida Sans Unicode';
}

.ql-font span[data-value='tahoma']::before,
.ql-font span[data-label='Tahoma']::before {
  font-family: 'Tahoma';
}

.ql-font span[data-value='timesNewRoman']::before,
.ql-font span[data-label='Times New Roman']::before {
  font-family: 'Times New Roman';
}

.ql-font span[data-value='trebuchetMS']::before,
.ql-font span[data-label='Trebuchet MS']::before {
  font-family: 'Trebuchet MS';
}

.ql-font span[data-value='verdana']::before,
.ql-font span[data-label='Verdana']::before {
  font-family: 'Verdana, Geneva, sans-serif';
}

.ql-font span[data-value='openSans']::before,
.ql-font span[data-label='open']::before {
  font-family: 'Open Sans,sans-serif';
}

.ql-font span[data-value='Poppins']::before,
.ql-font span[data-label='poppins']::before {
  font-family: 'Poppins';
}

.ql-font span[data-value='poppinsBold']::before,
.ql-font span[data-label='Poppins Bold']::before {
  font-family: 'Poppins-Bold';
}

.ql-font span[data-value='poppinsSemiBold']::before,
.ql-font span[data-label='Poppins SemiBold']::before {
  font-family: 'Poppins SemiBold';
}

.ql-font-arial {
  font-family: 'Arial';
}

.ql-font-poppins {
  font-family: 'Poppins';
}

.ql-font-poppinsBold {
  font-family: 'Poppins-Bold';
}

.ql-font-poppinsSemiBold {
  font-family: 'Poppins SemiBold';
}

.ql-font-arialBlack {
  font-family: 'Arial Black';
}

.ql-font-none {
  font-family: 'none';
}

.ql-font-brushScriptMT {
  font-family: 'Brush Script MT';
}

.ql-font-comicNewCourier {
  font-family: 'Comic New Courier', monospace;
}

.ql-font-georgia {
  font-family: 'Georgia';
}

.ql-font-helvetica {
  font-family: 'Helvetica';
}

.ql-font-latoBold {
  font-family: 'Lato Bold';
}

.ql-font-latoItalic {
  font-family: 'Lato Italic';
}

.ql-font-latoRegular {
  font-family: 'Lato Regular';
}

.ql-font-lucida {
  font-family: 'Lucida';
}

.ql-font-lucidaSansUnicode {
  font-family: 'Lucida Sans Unicode';
}

.ql-font-tahoma {
  font-family: 'Tahoma';
}

.ql-font-timesNewRoman {
  font-family: 'Times New Roman';
}

.ql-font-trebuchetMS {
  font-family: 'Trebuchet MS';
}

.ql-font-verdana {
  font-family: 'Verdana, Geneva, sans-serif';
}

.ql-font-openSans {
  font-family: 'Open Sans', sans-serif;
}

// to set the Font-Family dropdown width for stop the text overflow out of dropdown
.ql-snow .ql-picker.ql-font {
  min-width: 180px !important;
}

//Video upload
#video-button {
  font-size: smaller;
  cursor: pointer;
}

#video-button:hover {
  color: #007bff;
}

//drop zone modal

.dropzone-modalBtn {
  padding: 0 !important;
  outline: none !important;
  width: 105px !important;
  border: 1px solid !important;
  font-size: 14px !important;
  margin-left: 5px;
}

.dropzone-toolbar {
  background-color: #dfdfdf;
  padding: 7px 15px;
  margin-bottom: 15px;
  button:nth-child(1) {
    border-radius: 4px;
    outline: 0;
  }
  button:nth-child(2) {
    border-radius: 4px;
    outline: 0;
    margin-left: 7px;
  }
}

.innerContent {
  width: fit-content !important;
}

.dndImgTool {
  width: 80px !important;
  border: 1px solid !important;
  font-size: 14px !important;
  padding: 0px !important;
}

.dropzone-detacher {
  position: absolute;
  top: 0;
  right: 2px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
}

#image img {
  max-width: 980px;
  visibility: hidden;
}

.editorModal {
  overflow-x: auto;
}

.linkAlert {
  display: inherit;
}

.videoUploadModal {
  z-index: 1;
  background-color: white;
  position: absolute;
  padding: 5px;
  border: 1px solid grey;
  border-radius: 2px;
}

.videoUploadModal button {
  background-color: #23272b;
  border: 1px solid #1d2124;
  border-radius: 2px;
  color: white;
  margin-left: 5px;
  font-size: 12px;
}

.videoUploadModal button:disabled {
  cursor: not-allowed;
}

.videoLinkInput {
  height: 25px;
  width: 190px;
  font-size: 12px;
}

.accessibilityInputLabel {
  font-size: 15px;
}

.accessibilityCheckBox {
  position: absolute;
  top: 37px;
}
.ql-container .ql-editor .ql-video {
  pointer-events: none;
}
