@import "../../assets/scss/color.scss";

.interactive-lists {
  color: $black;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.custom-filter-dropdown {
  width: 11%;
}

.lint-custom-tanStack-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-bottom: 39px;
  margin-top: -10px;

  .selection-checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .selection-checkbox {
      display: inline-flex;
    }
  }

  th {
    color: $black !important;
    font-weight: normal;
    
    .title {
      color: #000;
      font-family: SourceSansPro-Regular;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
  }
    .sorting-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      background-color: transparent;
      border: 0;
      padding: 0;

      .title {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        flex: 0.8;
      }

      .icon {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        flex: 0.2;
      }
    }
  }

  tbody > tr:nth-child(odd) > td,
  tbody > tr:nth-child(odd) > th {
    background-color: $white_shadow !important;
    font-size: 14px !important;
    --bs-table-bg-type: $white_shadow !important;
  }

  tbody > tr:nth-child(even) > td,
  tbody > tr:nth-child(even) > th {
    background-color: $white !important;
    font-size: 14px !important;
  }

  tbody {

    .form-check-input {
      cursor: pointer !important;
    }

    .form-check-input:checked {
      background-color: $color_ultramarine;
      border-color: $color_ultramarine;
    }

    .td-capitalize {
      text-transform: capitalize;
    }

    td {
      vertical-align: middle !important;
      word-break: "break-word";

      .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 110px;
      }
    }
    a {
      color: $black;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
.interactive-list-wrapper {
  .form-group {
    margin-bottom: 0;
    position: relative;

    select.form-control {
      width: 172px;
      border: 1px solid $dim-grey;
      border-radius: 4px;
      outline: none;
      color: $black;
      font-size: 12px;
      cursor: pointer;

      &:focus {
        box-shadow: none;
      }
    }

    select:disabled {
      cursor: default;
    }


    .arrow-down {
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid $color_dorado;
      position: absolute;
      top: 38%;
      right: 6%;
      pointer-events: none;
    }
  }
}

.search {
  border: 0;
  outline: none;
  border-bottom: 1px solid $color_gray;
  transition: width 0.7s cubic-bezier(0, 0.795, 0, 1);
  font-size: 14px;
  width: 250px;
}
.search-icon-normal {
  position: absolute;
  right: 85%;
  top: 30%;
  &:hover {
    fill: $color_ultramarine;
  }
}
.search-icon {
  position: absolute;
  right: 6%;
  top: 44%;
}
@media (max-width: 400px) {
  .search {
    width: 150px;
    padding-right: 1.25rem;
  }
  .search-icon {
    position: absolute;
    right: 21%;
    top: 21%;
  }
}

.loree-custom-tanStack-table-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dropdown-icon {
    position: absolute;
    right: 7px;
    top: 10px;
  }

  .pagination {
    align-items: center;
    margin-bottom: 0;

    .disabled {
      cursor: not-allowed;

      .page-link {
        span {
          color: #8F8F8F;
        }
      }
    }
  }

  .page-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;

    span {
      color: #112299;
      font-size: 20px;
      margin-top: -5px;
    }
  }

  .page-link:focus {
    box-shadow: none;
  }

  .navigation,
  .current-page-info,
  .pagination-options {
    display: inline-flex;
    align-items: center;

    .dropdown-toggle-style {
      background-color: #fff !important;
      color: #000 !important;
      padding:5px 22px 5px 10px !important;
      border-radius: 6px !important;
      border: 1px solid #DDE0E4 !important;
      background: #FFF !important;
      gap: 10px !important;
      font-size: 12px !important;
    }

    .dropdown-menu {
      min-width: 56px !important;
      font-size: 12px !important;
      padding: 0 !important;
    }

    .dropdown-item {
      &:hover {
        color: #fff !important;
        background-color: #112299 !important;
      }
    }

    .dropdown-toggle::after {
      content: none !important;
    }
  }

  .current-page-info {
    justify-content: center;

    .option {
      margin-right: 10px;

      .text-style {
        color: #000;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  .pagination-options {
    justify-content: end;

    .option {
      margin-right: 10px;
      font-size: 12px;
    }
  }
}

.loree-custom-tanStack-table-pagination {
  position: fixed;
  background: $white;
  bottom: 0px;
  width: 100%;
}