.customize-container {
  overflow-y: auto;
  width: 202px;
  max-height: unset;
  height: 75vh;

  .accordion-button {
    padding: 0px !important;
    box-shadow: none !important;
    background: none !important;
  }

  .unit-placeholder {
    border: 1px solid #6c757d;
    border-radius: 5px;
    text-align: center;
  }
}

.title-header {
  position: fixed;
  background-color: #fff;
  z-index: 5;
}

.alignment-button-group {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.margin-group {
  display: flex;
  width: 115px;
}

.background-color-button {
  display: flex;
  justify-content: space-between;
}

.border-radius-form {
  display: flex;
  justify-content: space-between;
}

.customize-toggle {
  position: absolute;
  margin-left: -16px;
  cursor: pointer;
  z-index: 6;
}

.section-accordion {
  margin-top: 12px;
  border: 1px solid #112299;
  border-radius: 6px;
  padding: 10px;

  .inner-content {
    height: 25px;
  }
}

.section-content {
  border-top: 1px solid #8f8f8f;
  padding-top: 10px;
  margin-top: 10px;
}

.option-content {
  border-bottom: 1px dashed #8f8f8f;
}

.design-section-text {
  color: #000;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 600;
}

.accordion-title {
  color: #000;
  font-family: SourceSansPro-Regular;
  font-size: 16px;
  font-weight: 400;
  margin-left: 12px;
  cursor: pointer;
}

.dropdown-box.btn-primary {
  display: flex;
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid #707070;
  background: #fff;
  color: #000;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  z-index: 0 !important;

  &:after {
    display: none;
  }

  &:focus-visible {
    box-shadow: none !important;
  }

  &:hover,
  &:active,
  &.show {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #112299;
  }
}

.dropdown-icon {
  position: absolute;
  right: 12px;
  top: 12px;
}

.fontsize-dropdown.btn-primary {
  width: 100px;
  margin-right: 13px;
  border-radius: 6px !important;
}

.dropdown-input-menu.dropdown-menu {
  width: 100%;
  border-color: #112299;
  height: 190px;
  overflow-x: auto;
  padding: 0;

  .dropdown-item:hover {
    background-color: #112299;
    color: #fff;
  }
}

.weight-dropdown.dropdown-menu {
  height: 100px;
}

.alignment-buttons.btn-primary {
  background-color: #fff !important;
  border: none;

  &:active {
    background-color: #fff !important;
  }
}

.alignment-buttons.btn-primary.btn.active {
  z-index: inherit;
  svg {
    path {
      fill: #112299 !important;
    }
  }
}

.margin-top-input.form-control {
  border: none;
  border-radius: 0;
  border-top: 1px dashed #112299;
}

.margin-bottom-input.form-control {
  border: none;
  border-radius: 0;
  border-bottom: 1px dashed #112299;
  margin-left: 10px;
}

.color-picker.btn-primary {
  width: var(--Loree-Padding, 20px);
  height: var(--Loree-Padding, 20px);
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  background: #72c069;

  &:active,
  &:hover {
    border-color: #e9e9e9 !important;
  }
}

.checkbox-label {
  label {
    cursor: pointer;
  }
  input[type='checkbox'] {
    cursor: pointer;
  }
}

.icon-label {
  input[type='radio']:checked:after {
    width: 8px;
    height: 8px;
    border-radius: 15px;
    top: -26px;
    left: 3px;
    position: relative;
    background-color: #112299;
    content: '';
    display: inline-block;
    visibility: visible;
  }

  input[type='radio']:checked::before {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: -2px;
    left: 0px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #112299;
    background-color: white;
  }

  input[type='radio']:not(:checked)::before {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: -2px;
    left: 0px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #112299;
    background-color: white;
  }
}

.links-tab-label {
  margin-bottom: 0 !important;
  input[type='radio'] {
    accent-color: #112299;
    margin-right: 5px;
  }
}

#color-picker > :not(.border-element-container) {
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    /* Firefox */
    -moz-appearance: textfield;
  }
}
#color-picker {
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    width: 100%;
  }
  input[step='1'] {
    width: 40px;
    margin-left: 6px;
    margin-top: 2%;
  }
}
