@import '../../assets/scss/color.scss';

.colorpicker-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.font-colorpicker {
  z-index: 2;
}

.bg-colorPicker {
  z-index: 1;
}

.custom-header-title {
  color: #000;
  font-family: SourceSansPro-Regular;
  font-size: 16px;
  font-weight: 400;
  margin-left: 12px !important;
}

.selected-section {
  color: $color_ultramarine !important;
}

.space-group {
  display: flex;
  margin-right: 60px;
  margin-top: 12px;
}

.space-top-input.form-control {
  border: none;
  border-radius: 0;
  margin-left: 10px;
}

.space-left-input.form-control {
  border: none;
  border-radius: 0;
}

.space-right-input.form-control {
  border: none;
  border-radius: 0;
}

.space-bottom-input.form-control {
  border: none;
  border-radius: 0;
  margin-left: 10px;
}

.space-radius-input.form-control {
  display: flex;
  width: 60px;
  padding: 5px 10px;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #8f8f8f;
  background: #fff;
}

#loree-cp2 #color-picker {
  border: 1px solid #000d9c;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  z-index: 100;
}
