// Global custom styles
@import url(../src/assets/font/customFont.css);

body {
  color: #000000 !important;
  font-family: "SourceSansPro-Regular" !important;
}

.btn-pill {
  border-radius: 16px !important;
}
.customRadioButton {
  accent-color: #112299;
  cursor: pointer;
}

.btn-white {
  color: #212529 !important;
  background-color: #ffffff !important;
  box-shadow: none !important;
}

.icon-plus:after {
  font-family: "Font Awesome 5 Free";
  content: "\f067";
  float: right;
  font-weight: 900;
}

.icon-minus:after {
  font-family: "Font Awesome 5 Free";
  content: "\f068";
  float: right;
  font-weight: 900;
}

.icon-down-arrow:after {
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  float: right;
  font-weight: 900;
}

.icon-right-arrow:after {
  font-family: "Font Awesome 5 Free";
  content: "\f105";
  float: right;
  font-weight: 900;
}

.btn-white:hover {
  background-color: #212529 !important;
  color: #ffffff !important;
}

.square {
  height: 30px;
  width: 30px !important;
}

.portrait {
  height: 110px !important;
  width: 30px !important;
}

// form customisation
.small-card {
  user-select: none;
  width: 100%;
  min-width: 5rem !important;
  padding: 1rem 0.5rem;
  box-sizing: border-box;
  min-height: 90px;
  font-size: 11px;
  font-weight: lighter;
  text-align: center;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px !important;
  margin: 10px 2.5% 5px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.15);
  max-width: 6rem;

  .border {
    display: inline-block;
    width: 4rem;
    height: 3rem;
    background-color: #f5f5f5;
    margin: 0 auto;
    position: relative;
  }
}

.card-input-element {
  display: none;
}

.card-input {
  margin-right: 10px;
  border: none !important;
}

.card-input:hover {
  cursor: pointer;
}

.card-input-element:checked + .card-input {
  background-color: transparent;
}

// top header active button
.navbtn .active {
  background-color: #212529 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

// Removing scroll-bar in accordion
.ql-editor {
  overflow-y: hidden;
  height: auto;
}

.ql-editor:focus-visible {
  outline: black auto 1px !important;
}
.nav-link:focus-visible {
  outline: black auto 1px !important;
}

// Hiding the dummy state of mcq component
.hidden {
  display: none !important;
}

// For color picker width
.sketch-picker {
  width: auto !important;
}

.rounded-0 {
  --bs-accordion-border-radius: 0px !important;
  --bs-accordion-inner-border-radius: 0px !important;
}

.accordion {
  .card-header {
    cursor: pointer;
  }
}

.mw-100 {
  min-width: 100px !important;
}

.close-btn {
  line-height: 1 !important;
}

.margin {
  margin: 50px 10px 5px 10px;
}

.padding {
  padding-left: 2px !important;
}

.accordion img {
  max-width: 100%;
  height: auto;
}

.fright {
  float: right;
}

.collapsing {
  transition: height 0.1s ease !important;
}

.accordion-button::after {
  content: none !important;
}

.custom-accordion {
  border: none !important;
  background-color: transparent;
  border-radius: 0px;
}

#customAccordion {
  .card {
    --bs-card-inner-border-radius: 0px;
    --bs-card-border-width: 0px;
  }
}

.text-left {
  text-align: left !important
}

.text-right {
  text-align: right !important
}

.no-content-container {
  position: absolute;
  top: 50%;
  font-size: 18px;
  color: #8F8F8F;
  width: 100%;
  text-align: center;
}

.toast-body {
  padding:10px !important;
}

.tryAgainContainer {
  position: relative;
  margin: 0 auto;
  justify-content: center;
  display: flex;
}

.editButtonContainer {
  margin: 0 auto;
  position: relative;
  justify-content: end;
  display: flex;
}
