@import '../../assets/scss/color.scss';

.title-text {
  color: $color_ultramarine;
  font-family: SourceSansPro-Regular;
  font-size: 16px;
  font-weight: 600;
}

.form-wrapper {
  width: calc(100% - 202px) !important;
}

.form-body-wrapper {
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.selected-section {
  color: $color_ultramarine !important;
}

.preview-button.btn-primary {
  display: flex;
  padding: 5px 20px;
  border-radius: 4px;
  border: 1px solid $color_ultramarine;
  background: #fff;
  color: $color_ultramarine;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  width: 89px;

  &:hover,
  &:active,
  &:focus-visible {
    color: $color_ultramarine !important;
    background-color: #fff !important;
  }
}

.accordion-nav {
  padding-bottom: 10px;
  border-bottom: 1px solid #707070;
}

.title {
  color: #000;
  text-align: center;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 400;
}

.lint-custom-input.form-control {
  display: flex;
  height: 38px;
  padding: 5px 12px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #707070;
  background: #fff;
  color: #000;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 400;

  &:focus {
    border-color: #000;
    outline: 0;
    box-shadow: none;
  }
}

.section-style {
  color: $color_ultramarine;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.section-button.btn-primary {
  background-color: #fff;
  border-color: #fff;
  padding: 0px;
  margin-left: 12px;
  z-index: 1;
  position: sticky;

  &:hover,
  &:focus-visible {
    background-color: #fff !important;
    border-color: #fff !important;
  }
}

.delete-icon {
  margin-top: 0.5px;
}

.component-body {
  position: sticky;
  background-color: #fff;
  z-index: 1;
}

.addpanel-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px dashed rgb(17, 34, 153);
}

.addpanel-button.btn-primary {
  border: 1px solid rgb(17, 34, 153);
  margin: auto 0px;
  font-size: 13px;
  color: white;
  position: absolute;
  background: $color_ultramarine;
  margin-bottom: 15px;
  display: flex;
  padding: 18px;
  align-items: center;
  padding-left: 24px;
  gap: 5px;
  border-radius: 54px;
  width: 137px;
  height: 34px;

  &:active,
  &:hover,
  &:focus-visible {
    color: #fff !important;
    background-color: $color_ultramarine !important;
    border-color: $color_ultramarine !important;
  }
}

.text-area {
  resize: none !important;
  outline: none;

  &:focus {
    border-color: black !important;
    box-shadow: none !important;
  }
}

.form-check-input:checked {
  background-color: $color_ultramarine !important;
  border-color: $color_ultramarine !important;
  border-color: white !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}
.addoption-button.btn-primary {
  border: 1px solid $color_ultramarine;
  font-size: 13px;
  color: $color_ultramarine;
  position: absolute;
  background: white;
  margin-bottom: 15px;
  display: flex;
  padding: 18px;
  align-items: center;
  padding-left: 24px;
  gap: 5px;
  width: 126px;
  height: 34px;
  font-weight: bold;

  &:hover,
  &:active,
  &:focus-visible {
    color: $color_ultramarine !important;
    background-color: white !important;
    border-color: $color_ultramarine !important;
  }
}

.width-metric-input.input-group-text {
  padding: 0px 10px 3px 21px;
  font-size: 14px;
  width: 60px;
  border-radius: 6px !important;
  border: 1px solid #707070;
  background: rgba(233, 233, 233, 0.5);
}

.font-input-box.form-control {
  border-radius: 6px !important;
  margin-right: 12px;
}

.icon-items {
  display: grid;
}
.global-note-message {
  text-align: center;
  color: #707070;
  font-size: 12px;
  font-weight: 400;
}
.icon-label,
.links-tab-label {
  color: #000;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer !important;
  label {
    cursor: pointer !important;
  }
  input[type='radio' i] {
    cursor: pointer !important;
  }
}
.createInteractiveButton {
  font-size: 16px;
  input[type='radio']:checked:after {
    top: -32px;
    left: 3px;
    position: relative;
  }
  input[type='radio']:checked::before {
    width: 16px;
    height: 16px;
    top: -3px;
    left: -1px;
    position: relative;
  }
  input[type='radio']:not(:checked)::before {
    top: -5px;
    left: 0px;
    position: relative;
  }
}

#input-webaddress {
  border: 1px solid #909090;
}

#input-webaddress:focus {
  border-color: $color_ultramarine;
  box-shadow: 0 0 0 0.2rem rgba(0, 13, 156, 0.25) !important;
}

//Footer styles
.footer-cancel-btn.btn:disabled.btn-primary {
  padding: 7px 20px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid $color_ultramarine;
  background: #fff;
  color: $color_ultramarine;
  text-align: center;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 600;
  opacity: 1;
}

.footer-create-btn.btn:disabled {
  padding: 7px 20px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid $color_ultramarine;
  background: $color_ultramarine;
  color: #fff;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.5;
}

.footer-cancel-btn.btn-primary {
  padding: 7px 20px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid $color_ultramarine;
  background: #fff;
  color: $color_ultramarine;
  text-align: center;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 600;
  opacity: 1;

  &:active,
  &:hover,
  &:focus-visible {
    background-color: #fff !important;
    color: $color_ultramarine !important;
  }
}

.footer-create-btn.btn-primary {
  padding: 7px 20px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid $color_ultramarine;
  background: $color_ultramarine;
  color: #fff;
  text-align: center;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 600;
  opacity: 1;

  &:active,
  &:focus-visible,
  &:hover {
    background-color: $color_ultramarine !important;
    color: #fff !important;
  }
}

.footer-button-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white !important;
  text-align: center;
  z-index: 10;
}

.imageSliderFormBody,
.addMediaSection {
  border: 1px dashed $color_ultramarine;
  border: 2px dashed $color_ultramarine;
  border-radius: 5px;
  color: $color_ultramarine;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.img-container {
  display: inline;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
  .close {
    position: absolute;
    right: 0;
    top: -10px;
    border-radius: 20px;
    background-color: #fff;
    width: 24px;
    height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    box-shadow: 0 1rem 3rem #585858 !important;
  }
}

.image-wrapper {
  position: relative;
  overflow: hidden;
}

.img-container .overlay,
.interactive-image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(128, 128, 128);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
  pointer-events: none;
}

.img-container .overlay.visible,
.interactive-image-container .overlay.visible {
  opacity: 0.5;
}

.img-container .remove-img-btn,
.img-container .replace-img-btn,
.interactive-image-container .remove-img-btn,
.interactive-image-container .replace-img-btn {
  width: 80px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #112299;
  gap: 10px;
  padding: 5px 20px;
}

.img-container .remove-img-btn,
.interactive-image-container .remove-img-btn {
  background-color: white;
  color: #112299;
  margin-right: 20px;
}

.img-container .replace-img-btn,
.interactive-image-container .replace-img-btn {
  background-color: #112299;
  color: white;
  margin-left: 20px;
}

.interactive-image-container .image-wrapper-container {
  position: relative;
}

.img-container .btn-container,
.interactive-image-container .btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  pointer-events: none;
}

.img-container .btn-container button,
.interactive-image-container .btn-container button {
  pointer-events: auto;
}

.lint-image-editor-text-area {
  resize: none !important;
  outline: none;
}

.interactive-image-container {
  display: inline;
  position: relative;

  .close {
    position: absolute;
    right: 0;
    top: -10px;
    border-radius: 20px;
    background-color: #fff;
    width: 24px;
    height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    box-shadow: 0 1rem 3rem #585858 !important;
  }

  img {
    width: 100%;
    height: 360px;
    object-fit: scale-down;
  }
}

.right-container {
  resize: none !important;
  outline: none;
  border-radius: 5px;
}

.rnd-label {
  display: block;
  background-color: $color_ultramarine;
  color: white;
  word-break: break-all;
  overflow: hidden;
  height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: SourceSansPro-Regular;
  font-size: 11px;
  min-width: 20px;
  line-height: 14px;
}

.button-wrapper {
  button {
    display: block;
    background-color: #fff;
    border: 1px solid $color_ultramarine !important;
    padding: 10px;
    border-radius: 5px;

    &:hover,
    &:active {
      background-color: #fff !important;
    }

    &:disabled {
      background: white !important;
      opacity: 35% !important;
    }
  }
}

.editor-modal {
  height: 503px;
  .active {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
}
