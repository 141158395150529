@import "../../../assets/scss/color.scss";

.media-details-modal-wrapper{
  padding: 20px;

  .media-details-preview-wrapper {
    border: 1px solid $color_ultramarine;
    padding: 20px;
    justify-content: center;
  }

  .lint-image-preview{
      width: 100%;
      height: 100%;
      object-fit: scale-down;
  }

  .text-danger{
    font-size: 12px;
    color: $loree-red !important;
  }
  
  .media-preview-block {
    height: 235px;
    width: 100%;
    .material-icons {
      font-size: 120px;
    }
  }

  .media-thumbnail-media {
    width: 160px;
    border: 1px solid $color_gray;
  }

  label {
    font-size: 12px;
    color: $black;
  }

  input {
    border: 1px solid $color_gray;
    font-size: 14px;
    outline: none;
    line-height: 16px;
  }
  input.media-modal-file-name[disabled],
  input.media-modal-file-name:disabled {
    border: revert;
  }

  .lint-image-modal-text-area {
    resize: none !important;
    outline: none;
  }

  input[type=checkbox] {
    accent-color: $color_ultramarine;
  }

  label.media-thumbnail-btn {
    font-size: 12px;
    line-height: 15px;
    border-radius: 0;
    color: $color_ultramarine;
    background-color: $white;
    border-top: 1px solid $color_gray;
    cursor: pointer;
  }
}