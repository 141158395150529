@import "../../../../assets/scss/color.scss";

.lint-image-modal-right-container {
  border-radius: 4px;
  border: 1px solid $color_ultramarine;
  .nav-tabs {
    width: 76%;
    .nav-item {
      overflow: unset;
    }
  }
}

.icon-container {
  .category-list {
    border-right: 2px solid $color_light_gray;
    .selected-category {
      color: $color_ultramarine;
    }
  }
  .icon-list {
    .lint-icon:hover {
      color: $color_ultramarine;
    }
    .selected-icon {
      color: $color_ultramarine;
      border: 1px solid $color_ultramarine;
    }
    .material-icons {
      font-size: 32px;
      padding: 10px;
    }
  }
  .icon-list, .category-list {
    height: 200px;
    overflow-y: scroll;
  }
}
.disabled {
  background: #fff 0 0 no-repeat padding-box;
  opacity: .2;
}

.lint-image-row {
  margin: 20px 20px 10px 20px!important;
}

.image-modal-divider-content {
  height: 21px;
  border-bottom: 1px solid #909090;
  text-align: center;
}

.image-modal-divider-text {
  font-size: 12px;
  background-color: #FFFFFF;
  padding-top: 11px !important;
  margin: 0px -15px 0px;
  color: #909090;
}

.lint-media-modal {
  color: $black;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  


  select.form-select {
    width: 110px;
    border: 1px solid $dim-grey;
    border-radius: 4px;
    outline: none;
    color: $black;
    font-size: 12px !important;
    cursor: pointer;

    &:focus {
      box-shadow: none;
    }
  }

  select:disabled {
    cursor: default;
  }
}

.lint-custom-element-list-container {
  overflow: scroll;
  overflow-x: hidden;
  padding-left: 20px;
  height: 100%;

  .d-flex {
    padding: 5px;
    height: 50%;
    width: 16.3% !important;
  }

  .element-thumbnail:hover, .selected-media{
    color: $color_ultramarine;
    font-weight: bold;
    cursor: pointer;

    img {
      border: 1px solid $color_ultramarine;
    }
  }

  .lint-custom-element-list-thumbnail {
    height: 65px;
    background: $color_alto 0 0 no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    object-fit: scale-down;
  }

  .lint-custom-element-list-title {
    font-size: 14px;
    max-width: 104px;
  }
}

.lint-image-local-upload-input-message{
  font-size: 12px;
}

.lint-image-modal-body-content-right-container {

  .text-danger{
    p {
        color: $loree-red;
        font-size: 12px;
        padding: 0;
        margin: 0;
    }
  }
  .right-container-layout {

    .disable-image-upload {
      border-color: $color_gray !important;
      opacity: 0.3;
      p {
        color: $color_gray !important;
      }
    }

    .upload-local-image {
      border: 1px dashed $color_ultramarine;
      border-radius: 5px;
      padding: 26px 19px;

      .upload-image-icon-holder {
        cursor: pointer;
        p {
          font-size: 14px;
          color: $color_ultramarine;
          padding: 20px 10px 0px;
          text-align: center;
          font-weight: 600;
        }
      }
    }

    .image-modal-icon {
      position: absolute;
      padding: 10px 10px;
      min-width: 30px;
      z-index: 1;
    }

    .invalid-url-state {
      border-color: $loree-red !important;
    }

    input {
      width: 100%;
      height: 40px;
      padding: 5px 5px 5px 40px;
      border: 1px solid $color_ultramarine;
      border-radius: 4px;
      line-height: 2.5;

      &:focus-visible{
        outline: 0;
      }
    }

    button {
      background-color: $white;
      width: 100%;
      height: 36px;
      padding-left: 45px;
      border: 1px solid $color_gray;
      font-size: 12px;
      color: rgba(88, 88, 88, 0.4);
      text-align: left;
      vertical-align: middle;

      &.active {
        color: $black;
        opacity: 1;
      }
    }
  }
}
