.fa-spin {
  animation-duration: 1s;
  color: #112299 !important;
}

.loader-container {
  position: absolute;
  top: 45%;
  width: 100%;
  text-align: center;
}
