@import '../../assets/scss/color.scss';

.lint-custom-dropdown{
  .lint-custom-dropdown-toggle.btn-primary.dropdown-toggle {
    width: 111px;
    height: 30px;
    border: 1px solid #6c757d;
    color: #212529 !important;
    font-size: 14px;
    border-radius: 6px;
    padding: 5px 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background-color: transparent;

    &:active, &:focus-visible{
      background-color: transparent;
      border-color: #6c757d;
    }

    &:after {
      display: none;
  }

    span {
      left: 92px;
      bottom: 5px;
    }

    &:disabled {
      background-color: #e9ecef !important;
      opacity: 1;
      cursor: default !important;
    }
  }

  .dropdown-menu {
    min-width: 75px !important;
    max-height: 300px;
    overflow: auto;
  }

  .dropdown-item:active,.dropdown-item:active,.dropdown-item:hover{
    background-color: $color_ultramarine !important;
    color: #ffffff !important;
  }
}

.lint-custom-ellipsis {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  text-align: left;
}


.dropdown-icon {
  position: absolute;
  right: 12px;
  top: 12px,
}

.lint-customize-dropdown{
  .lint-custom-dropdown-toggle.btn-primary.dropdown-toggle {
    width: 100%;
    height: 30px;
    border: 1px solid #6c757d;
    color: #212529 !important;
    font-size: 14px;
    border-radius: 6px;
    padding: 5px 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background-color: transparent;

    &:active, &:focus-visible{
      background-color: transparent;
      border-color: #6c757d;
    }

    &:after {
      display: none;
    }

    span {
      left: 92px;
      bottom: 5px;
    }

    &:disabled {
      background-color: #e9ecef !important;
      opacity: 1;
      cursor: default !important;
    }
  }

  .dropdown-menu.show {
    width: 100%;
    max-height: 300px;
    overflow: auto;
  }

  .dropdown-item:active,.dropdown-item:active,.dropdown-item:hover{
    background-color: $color_ultramarine !important;
    color: #ffffff !important;
  }
}
